import pkg from 'axios'
const { request } = pkg
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

// VARS
const domain = window.location.origin
const client_id = process.env.VUE_APP_COGNITO_CLIENT_ID
const tenant = process.env.VUE_APP_AAD_TENANT

const OIDCUserManager = new UserManager({
    authority: `https://login.microsoftonline.com/${ tenant }/v2.0`,
    client_id,
    redirect_uri: domain,
    response_type: 'code',
    scope: 'profile openid',
    userStore: new WebStorageStateStore()
  })

const makeRequest = async ({ verb, route, data = null }) => {

    const request_object = {
        method: verb,
        url: window.location.origin + route
    }

    if (data) {
        request_object.data = data
    }
  
    const user = await OIDCUserManager.getUser()
    if (user && user.id_token) {
      request_object.headers = {
        Authorization: user.id_token
      }
    }
      
    return await request(request_object)
      .catch(async err => (err.response.status === 401) ? 
        await tokenExchange() :
        err
    )
}

const tokenExchange = async () => {
    const params = new URLSearchParams(window.location.search.substring(1))
    if(params.has('code') && params.has('state')) {
      await OIDCUserManager.signinRedirectCallback().then(() => {
        const url = document.location.href
        window.history.pushState({}, "", url.split("?")[0])
      })
    } else {
        await OIDCUserManager.signinRedirect().catch(err => console.error(err))
    }
  }

export { makeRequest as request, tokenExchange, OIDCUserManager as UserManager }